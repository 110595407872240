import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores } from '~/utils';
import Epic from './Epic';

type Props = {
  response?: EpicType.Response[];
};

const EpicContainer: FC<Props> = () => {
  const { epic } = useStores();

  const onSubmit = async (data: EpicType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    const response = await epic.onEpic(data);
    if (response) {
      const resp = await epic.getEpic({ id: response });

      setTimeout(() => {
        setSubmitting(false);
      }, 60000);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        application: '',
        objective: '',
        amount: 0,
      }}
    >
      <Epic />
    </Formik>
  );
};

export default observer(EpicContainer);
