import React, { FC } from 'react';

type Props = {
  onPress?: () => void;
  color?: string;
};

const Icon: FC<Props> = ({ onPress, color = '#4E89FD' }) => (
  <svg
    onClick={onPress}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill={color}
    viewBox="0 0 256 256"
    style={{
      cursor: 'pointer',
      zIndex: 1,
    }}
  >
    <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-69.66,42.34L160,188.69V128a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z" />
  </svg>
);

export default Icon;
