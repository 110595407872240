import { getTheme, ifStyle, pxToRem, styled } from '~/utils';
import { MDXEditor } from '@mdxeditor/editor';

// Colors
const primaryContrast = getTheme('primary.contrast');
const primaryLight = getTheme('primary.light');
const secondaryContrast = getTheme('secondary.contrast');
const secondaryDark = getTheme('secondary.dark');
const primaryMain = getTheme('primary.main');

// Spacing
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');

// Radius
const mediumRadius = getTheme('mediumRadius');

// Breakpoints
const inMobileAndTablet = getTheme('inMobileAndTablet');

// Ifs
const isVisible = ifStyle('visible');

export const Content = styled.button`
  border: 0;
  cursor: pointer;
  background-color: #122D46;
  width: 100%;
  border: 1px solid ${primaryContrast};
  border-radius: ${mediumRadius};
  margin-bottom: ${mediumSpacing};
  padding: ${mediumSpacing};

  @media ${inMobileAndTablet} {
    padding: ${smallSpacing};
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionText = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h3`
  text-align: left;
  font-size: ${pxToRem(20)};
  color: ${primaryLight};
`;

export const Description = styled.p`
  text-align: left;
  font-size: ${pxToRem(16)};
  color: ${primaryLight};
`;

export const ContentCollapse = styled.div`
  display: flex;
  flex-direction: column;
  color: ${primaryLight};
  text-align: left;
`;

export const SectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: ${pxToRem(80)};
  justify-content: space-between;
  align-items: center;

  @media ${inMobileAndTablet} {
    flex-direction: row;
    justify-content: space-evenly;
    height: ${pxToRem(50)};
    width: ${pxToRem(120)};
  }
`;

type DownloadProps = {
  visible?: boolean;
};

export const DownloadOptionsSection = styled.div<DownloadProps>`
  width: ${pxToRem(100)};
  height: ${pxToRem(100)};
  position: absolute;
  right: ${pxToRem(115)};
  border-radius: ${pxToRem(10)} ${pxToRem(10)} 0px ${pxToRem(10)};
  transition: opacity 0.2s linear;
  opacity: ${isVisible(1, 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${primaryContrast};
  margin-bottom: ${pxToRem(80)};
  background-color: ${primaryLight};

  @media ${inMobileAndTablet} {
    right: ${pxToRem(115)};
    margin-bottom: ${pxToRem(90)};
  }
`;

export const DownloadText = styled.h3`
  padding-top: ${pxToRem(4)};

  &:hover {
    color: ${primaryMain};
    transition: 0.5s;
    cursor: pointer;
  }
`;

export const MDXEditorCustom = styled(MDXEditor)`
  color: ${primaryLight};
  .mdxeditor-custom {
    color: ${primaryLight};
    text-align: left;
    font-size: ${pxToRem(16)};
  }
`;
