import React, { FC } from 'react';

type Props = {
  color?: string;
  opened?: boolean;
  standard?: boolean;
  style?: any;
  onPress?: () => void;
};

const Icon: FC<Props> = ({
  color = '#081E38',
  opened = false,
  standard = false,
  style,
  onPress = () => {},
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onPress}
  >
    <path
      d="M13.5238 13.5238L2.76082 24.2868C2.45752 24.5901 2.04617 24.7605 1.61725 24.7605C1.18833 24.7605 0.776974 24.5901 0.473681 24.2868C0.170388 23.9835 3.19571e-09 23.5722 0 23.1432C-3.19571e-09 22.7143 0.170388 22.303 0.473681 21.9997L10.0944 12.3816L0.476372 2.76082C0.326197 2.61064 0.207071 2.43236 0.125796 2.23614C0.0445219 2.03993 0.0026903 1.82963 0.0026903 1.61725C0.00269029 1.40487 0.0445219 1.19457 0.125796 0.998355C0.207071 0.802141 0.326197 0.623857 0.476372 0.473682C0.626548 0.323506 0.804832 0.20438 1.00105 0.123106C1.19726 0.0418316 1.40756 -1.58236e-09 1.61994 0C1.83232 1.58236e-09 2.04262 0.0418316 2.23883 0.123106C2.43505 0.20438 2.61333 0.323506 2.76351 0.473682L13.5265 11.2367C13.6768 11.3868 13.796 11.5652 13.8773 11.7615C13.9586 11.9579 14.0002 12.1683 14 12.3808C13.9997 12.5933 13.9576 12.8036 13.8758 12.9998C13.7941 13.1959 13.6745 13.374 13.5238 13.5238Z"
      fill={color}
      fillOpacity="0.890196"
      style={{
        transition: 'transform 0.5s ease',
        transform: standard
          ? 'rotate(0deg)'
          : opened
          ? 'rotate(90deg)'
          : 'rotate(-90deg)',
        transformOrigin: 'center',
      }}
    />
  </svg>
);

export default Icon;
