import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { GeneratorAPI } from '~/services/api';

export default class StoryStore {
  @observable
  data: StoryType.Response[] = [];

  @observable
  title: string = '';

  @observable
  description: string = '';

  @observable
  content: any = [];

  @observable
  _id: string = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'StoryStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  @action
  onStory = async (data: StoryType.Forms): Promise<any> => {
    const rsp = await GeneratorAPI.onStory(data);
    return rsp;
  };

  @action
  getStory = async (data: StoryType.GetResponse): Promise<boolean> => {
    this.data = [];
    const rsp = await GeneratorAPI.getStory(data);
    if (rsp.length === 0) {
      return false;
    }else{
      this.title = rsp[0].title;
      this.description = rsp[0].description;
      this.content = rsp[0].content;
      this._id = rsp[0]._id;
      return true;
    };
  };

  @action
  updateStory = async (_id: string, story: any): Promise<any> => {
    const rsp = await GeneratorAPI.updateStory(_id, story);
    return rsp;
  }

  @action
  setStoryFeedback = async (_id: string, storyId: Number, feedback: Number): Promise<any> => {
    const rsp = await GeneratorAPI.onStoryFeedback(_id, storyId, feedback);
    return rsp;
  }
}
