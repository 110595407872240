import { getTheme, pxToRem, styled } from '~/utils';
import ReactLoading from 'react-loading';
import TextField from '@material-ui/core/TextField';

// Colors
const primaryLight = getTheme('primary.light');
const primaryMain = getTheme('primary.main');
const primaryDark = getTheme('primary.dark');
const primaryContrast = getTheme('primary.contrast');
const secondaryDark = getTheme('secondary.dark');

// Spacing
const sceneSpacing = getTheme('sceneSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');

// Query
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${primaryDark};
  flex-direction: column;
`;

export const ButtonBox = styled.span`
  display: flex;
  width: 40px;
  height: 40px;
  margin: ${pxToRem(8)} ${pxToRem(24)};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledReactLoading = styled(ReactLoading)`
  flex: 1;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${smallSpacing};
  background-color: ${primaryDark};

  @media ${inMobileAndTablet} {
    padding: ${smallSpacing};
  }
`;

export const SectionFields = styled.div`
  margin-top: ${pxToRem(25)};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${primaryContrast};
  margin-bottom: ${pxToRem(40)};
  padding-bottom: ${pxToRem(40)};
`;

export const AlignFields = styled.div`
  display: flex;
  width: 95%;
  flex-direction: column;
`;

export const WrapperFields = styled.div`
  width: 100%;
  display: flex;

  @media ${inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const TextEmpty = styled.h3`
  text-align: center;
  color: ${primaryContrast};
`;

export const CustomTextField = styled(TextField)`
  & label {
    font-size: 1em;
    color: #fff;
  }
  & .MuiInputBase-root {
    background-color: ${secondaryDark};
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    color: #fff;
  }
  & label.Mui-focused {
    color: ${primaryMain};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primaryMain};
    }
  }
`;

export const Title = styled.h3`
  text-align: left;
  font-size: 1em;
  color: ${primaryLight};
  display: flex;
  align-items: center;
`;

export const ToogleContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${pxToRem(8)};
  align-content: center;
`;
