import '@mdxeditor/editor/style.css';
import React, { FC, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import Arrow from '~/assets/svg/Arrow';
import {
  Content,
  ContentCollapse,
  SectionButtons,
  SectionHeader,
  SectionText,
  Title,
} from './styles';
import { CardJira } from '~/components';
import { useFormikContext, useStores } from '~/utils';

type Props = {
  title: string;
  type: string;
  issue?: any;
};

const CollapseJiraModule: FC<Props> = ({ title, type, issue }) => {
  const { setFieldValue } = useFormikContext<StoryType.Forms>();
  const [opened, setOpened] = useState(false);
  const ref = useRef<any>();

  const selectItem = (issue: any) => {
    if (type == 'Story') {
      setFieldValue('epic', issue.summary);
      issue.description 
        ? setFieldValue('description', issue.description)
        : setFieldValue('description', '');
    }
    if (type == 'Test') {
      setFieldValue('resource', issue.summary);
      setFieldValue('system', issue.project)
      issue.epic
        ? setFieldValue('epic', issue.epic)
        : setFieldValue('epic', '');
    }
  };

  return (
    <Content>
      <SectionHeader>
        <SectionText onClick={() => setOpened(!opened)}>
          <Title>{title}</Title>
          {/* <Description>{description}</Description> */}
        </SectionText>

        <SectionButtons>
          <Arrow
            opened={!opened}
            onPress={() => setOpened(!opened)}
            color={'#fff'}
          />
        </SectionButtons>
      </SectionHeader>
      <Collapse isOpened={opened}>
        <ContentCollapse ref={ref}>
          {issue &&
            issue.map((item: any) => (
              <CardJira data={item} onPress={() => selectItem(item)} />
            ))}
        </ContentCollapse>
      </Collapse>
    </Content>
  );
};
export default CollapseJiraModule;
