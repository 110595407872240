import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Dict } from 'styled-components/dist/types';
import { GeneratorAPI } from '~/services/api';

export default class EpicStore {
  @observable
  data: EpicType.Response[] = [];
  
  @observable
  title: string = '';

  @observable
  description: string = '';

  @observable
  content: any = [];
  
  @observable
  _id: string = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'EpicStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  @action
  onEpic = async (data: EpicType.Forms): Promise<any> => {
    const rsp = await GeneratorAPI.onEpic(data);
    return rsp
  };

  @action
  getEpic = async (data: EpicType.GetResponse): Promise<boolean> => {
    this.data = [];
    const rsp = await GeneratorAPI.getEpic(data);
    if (rsp.length === 0) {
      return false;
    }
    else {
      this.title = rsp[0].title;
      this.description = rsp[0].description;
      this.content = rsp[0].content;
      this._id = rsp[0]._id;
      return true;
    };
  }
  
  @action
  updateEpic = async (_id: string, epic: any): Promise<any> => {
    const rsp = await GeneratorAPI.updateEpic(_id, epic);
    return rsp;
  }

  @action
  setEpicFeedback = async (_id: string, epicId: Number, feedback: Number): Promise<any> => {
    const rsp = await GeneratorAPI.onEpicFeedback(_id, epicId, feedback);
    return rsp;
  }
}
