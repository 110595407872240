import { Request } from '~/services';
import { alert, REACT_APP_API_EPIC_GEN, REACT_APP_API_STORY_GEN, REACT_APP_API_INTEGRATION_ALM_GEN, REACT_APP_API_TEST_CASE_GEN } from '~/utils';

export const onCaseTest = async ({
  system,
  epic,
  functionality,
  rules,
  userStory,
  acceptanceCriteria,
  denialCriteria,
  amount,
}: TestsType.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").post('/test-case', {
      system: system,
      epic: epic,
      functionality: functionality,
      rules: rules,
      user_story: userStory,
      acceptance_criteria: acceptanceCriteria,
      denial_criteria: denialCriteria,
      amount: amount,
    });
    return response.data;    
  } catch ( error: any ) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
};

export const getCaseTest = async ({
  id,
}: TestsType.GetResponse): Promise<TestsType.Response[]> => {
  try {
    let response;
    do {
      response = await Request(REACT_APP_API_TEST_CASE_GEN || "").get(`/test-case/${id}`);
      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } while (!response.data);
    return typeof response.data === 'object' ? [response.data] : response.data;
  } catch (error: any) {
    alert({
      message: error.message,
      type: 'error', 
      position: 'bottom-center'
    });
    return [];
  }
};

export const updateCaseTest = async (_id: string, testCase: any): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").put(`/test-case/${_id}/${testCase.id}`, { 
      id: testCase.id,
      summary: testCase.summary,
      description: testCase.description,
      scenery: testCase.scenary,
      feedback: testCase.feedback,
      alm_id: testCase.alm_id,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const onCaseTestFeedback = async (_id: string, testCase_id: Number, feedback: Number): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_TEST_CASE_GEN || "").patch(`/test-case/${_id}/${testCase_id}/feedback`, feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const onStory = async ({
  epic,
  description,
  business_rule,
  severity,
  amount,
}: StoryType.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_STORY_GEN || "").post('/story', {
      epic: epic,
      description: description,
      business_rule: business_rule,
      severity: severity,
      amount: amount,
    });
    return response.data;
  } catch ( error: any ) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
};

export const getStory = async ({
  id,
}: StoryType.GetResponse): Promise<StoryType.Response[]> => {
  try {
    let response;
    do {
      response = await Request(REACT_APP_API_STORY_GEN || "").get(`/story/${id}`)
      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } while (!response.data);
    return typeof response.data === 'object' ? [response.data] : response.data;
  } catch (error: any ) {
    alert({
      message: error.message,
      type: 'error', 
      position: 'bottom-center'
    })
    return [];
  }
};

export const updateStory = async (_id: string, story: any): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_STORY_GEN || "").put(`/story/${_id}/${story.id}`, { 
      id: story.id,
      summary: story.summary,
      description: story.description,
      acceptance_criteria: story.acceptance_criteria,
      story_points: story.story_points,
      feedback: story.feedback,
      alm_id: story.alm_id,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const onStoryFeedback = async (_id: string, story_id: Number, feedback: Number): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_STORY_GEN || "").patch(`/story/${_id}/${story_id}/feedback`, feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const getEpic = async ({
  id,
}: EpicType.GetResponse): Promise<EpicType.Response[]> => {
  try {
    let response;
    do {
      response = await Request(REACT_APP_API_EPIC_GEN || "").get(`/epic/${id}`)
      if (response.data.error) {
        throw new Error(response.data.error);
      }
    } while (!response.data);
    return typeof response.data === 'object' ? [response.data] : response.data;
  } catch (error: any) {
    alert({
      message: error.message,
      type: 'error', 
      position: 'bottom-center'
    });
    return [];
  }
};

export const onEpic = async ({
  objective,
  application,
  amount,
}: EpicType.Forms): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_EPIC_GEN || "").post('/epic', {
      application: application,
      objective: objective,
      amount: amount,
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
};

export const updateEpic = async (_id: string, epic: any): Promise<any> => {
  try {
    const response = await Request(REACT_APP_API_EPIC_GEN || "").put(`/epic/${_id}/${epic.id}`, { 
      id: epic.id,
      summary: epic.summary,
      description: epic.description,
      feedback: epic.feedback,
      alm_id: epic.alm_id, 
    });
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const onEpicFeedback = async (_id: string, epic_id: Number, feedback: Number): Promise<any> => {
  try{
    const response = await Request(REACT_APP_API_EPIC_GEN || "").patch(`/epic/${_id}/${epic_id}/feedback`, feedback);
    return response.data;
  } catch (error: any) {
    if (error.response.status === 503){
      alert({
        message: error.response.data.error,
        type: 'error', 
        position: 'bottom-center'
      });
    }else{
      alert({
        message: error.message,
        type: 'error', 
        position: 'bottom-center'
      });
    }
    return false;
  }
}

export const exportItemsToJira = async ({
  tenent_id,
  issues,
}: any): Promise<any> => {
  try {
    const { data } = await Request(REACT_APP_API_INTEGRATION_ALM_GEN || "").post(`/projects/${tenent_id}/issues`, issues);
    return data
  } catch ( error: any ) {
    return error
  }
};