import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, useStores } from '~/utils';
import Resource from './Tests';

type Props = {
  response?: TestsType.Response[];
};

const TestsContainer: FC<Props> = () => {
  const { caseTest } = useStores();

  const onSubmit = async (data: TestsType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    const response = await caseTest.onCaseTest(data);
    if (response) {
      const resp = await caseTest.getCaseTest({ id: response });
      
      setTimeout(() => {
        setSubmitting(false);
      }, 60000);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        system: '',
        epic: '',
        functionality: '',
        rules: '',
        userStory: '',
        acceptanceCriteria: '',
        denialCriteria: '',
        amount: 1,
      }}
    >
      <Resource />
    </Formik>
  );
};

export default observer(TestsContainer);
