import { getTheme, pxToRem, styled } from "~/utils";
import TextField from '@material-ui/core/TextField';

type SelectProps = {
  error?: boolean;
};
// Colors
const light = getTheme("colors.light");
const secondary = getTheme("colors.secondary");
const contrast = getTheme("colors.contrast");
const grayBg = getTheme("colors.grayBg");

// Radius
const cardRadius = pxToRem(10);

// Spacing
const minimalSpacing = getTheme("minimalSpacing");

// Colors
const primaryLight = getTheme('primary.light');
const primaryContrast = getTheme('primary.contrast');
const primaryDark = getTheme('primary.dark');
const secondaryDark = getTheme('secondary.dark');
const primaryMain = getTheme('primary.main');

// Spacing
const sceneSpacing = getTheme('sceneSpacing');
const mediumSpacing = getTheme('mediumSpacing');
const smallSpacing = getTheme('smallSpacing');

// Query
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${primaryDark}90;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
`;

export const Section = styled.div`
  width: 70%;
  min-height: 20vh;
  max-height: 90vh;
  background-color: ${secondaryDark};
  padding: ${minimalSpacing};
  border-radius: ${cardRadius};
  border: 2px solid ${contrast};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${smallSpacing};
  margin-bottom: ${mediumSpacing};
`;

export const SectionFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font-weight: 400;
  color: ${primaryLight};
  font-size: ${pxToRem(25)};
  width: 95%;
  word-wrap: break-word;
`;

export const Blocks = styled.div`
  width: 100%;
  margin: ${smallSpacing} 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 50vh;
`;

export const SectionSelect = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h4`
  font-size: ${pxToRem(16)};
  color: ${light};
  font-weight: 400;
  margin-bottom: ${minimalSpacing};
`;

export const SectionRulesAdded = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${minimalSpacing};
`;

export const CustomTextField = styled(TextField)`
  & label {
    font-size: 1em;
    color: #fff;
  }
  & .MuiInputBase-root {
    background-color: ${secondaryDark};
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    color: #fff;
  }
  & label.Mui-focused {
    color: ${primaryMain};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${primaryMain};
    }
  }
`;
