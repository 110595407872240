import '@mdxeditor/editor/style.css';
import React, { FC, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Arrow, Edit, Share } from '~/assets/svg';
import { colors } from '~/theme';
import If from '../If';
import {
  Content,
  ContentCollapse,
  SectionButtons,
  SectionHeader,
  SectionText,
  Title,
  Description
} from './styles';
import { CardResponse, ModalEditing } from '~/components';
import { observer, useFormikContext, useStores, alert, pxToRem } from '~/utils';
import { set } from 'lodash';

type Props = {
  item: any;
  type: string;
};

const CollapseResponseModule: FC<Props> = ({
  item,
  type,
}) => {
  const { jiraExport } = useStores();
  const { setFieldValue } = useFormikContext<StoryType.Forms>();
  const [modalOpened, setModalOpened] = useState(false);
  const [issue, setIssue] = useState<any>();
  const [editing, setEditing] = useState(false);
  const [opened, setOpened] = useState(false);
  const ref = useRef<any>();

  const selectItem = (issue: any) => {
    if (editing) {
      setIssue(issue);
      setModalOpened(true);
    }
  };

  const LimitedText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const exportItems = async () => {
    var response;
    if(type === 'Épico') {
      response = await jiraExport.exportEpicToJira('f24c99fdc5ee49368bcc783259d21eb6', 'IJ', 'Epic', issue);
    }else if(type === 'História') {
      response = await jiraExport.exportStoryToJira('f24c99fdc5ee49368bcc783259d21eb6', 'IJ', 'História', issue);
    }
    if (response) {
      alert({
        message: `Épico(s) exportado(s) com sucesso!`,
        type: 'success',
        position: 'bottom-center',
      });
    } else {
      alert({
        message: `Erro ao exportar épico(s): error`,
        type: 'error',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Content>
      <SectionHeader>
        <SectionText onClick={() => setOpened(!opened)}>
          <Title>{item.title}</Title>
          <If condition={!opened}>
            <Description>{LimitedText(item.description, 100)}</Description>
          </If>
          <If condition={opened}>
            <Description>{item.description}</Description>
          </If>
        </SectionText>

        <SectionButtons>
          <Arrow
            opened={!opened}
            onPress={() => setOpened(!opened)}
            color={'#fff'}
          />
        </SectionButtons>
      </SectionHeader>
      <Collapse isOpened={opened}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '1.5% 10% 1.5% 0',
          }}
        >
          <Edit
            onPress={() => {
              setEditing(!editing);
              jiraExport.toggleEditMode(!editing);
              if (!editing) {
                alert({
                  message: 'Modo de edição ativado',
                  type: 'info',
                  position: 'bottom-center',
                });
              } else {
                alert({
                  message: 'Modo de edição desativado',
                  type: 'info',
                  position: 'bottom-center',
                });
              }
            }}
            color={'#fff'}
          />
          <Share
            color={editing ? 'grey' : '#fff'}
            onPress={!editing ? () => exportItems() : () => {}}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: '4%',
          }}
        >
          {jiraExport.selectMode && !jiraExport.editMode ? (
            <div
              style={{
                color: '#fff',
              }}
            >
              {jiraExport.selectedCount === 0 ? (
                ''
              ) : jiraExport.selectedCount === 1 ? (
                <>{jiraExport.selectedCount} item selecionado.</>
              ) : (
                <>{jiraExport.selectedCount} itens selecionados.</>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <ContentCollapse ref={ref}>
          {item.content &&
            item.content.map((item: any) => (
              <CardResponse
                _id={item._id}
                data={item}
                onPress={() => selectItem(item)}
                type={type}
              />
            ))}
        </ContentCollapse>
      </Collapse>
      <If condition={modalOpened}>
        <ModalEditing
          issue={issue}
          _id={item._id}
          type={type}
          opened={modalOpened}
          setOpened={setModalOpened}
        />
      </If>
    </Content>
  );
};
export default observer(CollapseResponseModule);
