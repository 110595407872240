import React, { FC } from 'react';
import { Formik, useStores } from '~/utils';
import Resource from './Story';

type Props = {
  response?: StoryType.Response[];
};

const StoryContainer: FC<Props> = () => {
  const { story } = useStores();

  const onSubmit = async (data: StoryType.Forms, { setSubmitting }: any) => {
    setSubmitting(true);

    const response = await story.onStory(data);
    if (response) {
      const resp = await story.getStory({ id: response });
      
      setTimeout(() => {
        setSubmitting(false);
      }, 60000);
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        epic: '',
        description: '',
        business_rule: '',
        severity: '',
        amount: 0,
      }}
    >
      <Resource />
    </Formik>
  );
};

export default StoryContainer;
