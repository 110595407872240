import { getTheme, pxToRem, styled } from '~/utils';

// Colors
const primaryLight = getTheme('primary.light');
const primaryDark = getTheme('primary.dark');

// Radius
const mediumRadius = getTheme('mediumRadius');

// Spacing
const smallSpacing = getTheme('smallSpacing');
const mediumSpacing = getTheme('mediumSpacing');

// Breakpoints
const inMobileAndTablet = getTheme('inMobileAndTablet');

export const Content = styled.button`
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: top;
  cursor: pointer;
  background-color: ${primaryLight};
  width: 30%;
  height: ${pxToRem(130)};
  border-radius: ${mediumRadius};
  margin: ${smallSpacing};

  box-shadow: 0px 0px 5px 0px ${primaryDark}50;

  &:hover {
    background-color: #e6e6e6;
  }
`;

export const Image = styled.img`
  width: ${pxToRem(50)};
`;

export const TextContainer = styled.div`
  padding: ${smallSpacing} ${mediumSpacing};
`;

export const Title = styled.h3`
  text-align: left;
  margin-bottom: ${pxToRem(8)};
  font-size: ${pxToRem(20)};
  font-weight: 600; 
  color: #0d0d0d;
`;

export const Description = styled.p`
  width: 100%;
  text-align: left;
  font-size: ${pxToRem(16)};
  color: #262626;`
