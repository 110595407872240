import React, { FC, useEffect, useState } from 'react';
import { Tooltip, Switch } from '@material-ui/core';
import { Request } from '~/services';
import { Play, Jira } from '~/assets/svg';
import { CollapseResponse, Header, If, CollapseJira } from '~/components';
import { colors } from '~/theme';
import { alert, useStores, useFormikContext, REACT_APP_API_INTEGRATION_ALM_GEN } from '~/utils';
import {
  AlignFields,
  Background,
  ButtonBox,
  ButtonsContainer,
  Container,
  SectionFields,
  StyledReactLoading,
  WrapperFields,
  TextEmpty,
  CustomTextField,
  ToogleContent,
  Title
} from './styles';

type Props = {};

const Tests: FC<Props> = (): JSX.Element => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<TestsType.Forms>();
  const { caseTest } = useStores()
  const [autoAmount, setAutoAmount] = React.useState(true);
  const [ tenantId, setTenantID ]  = useState("f24c99fdc5ee49368bcc783259d21eb6");
  const [ project, setProject ]  = useState("IJ");
  const [ issueType, setIssueType ]  = useState("História");

  const defineAmount = () => {
    if (!autoAmount) {
      values.amount = 1;
      alert({
        message: 'A quantidade de testes gerados será definida manualmente',
        type: 'info',
        position: 'bottom-center',
      })
    } else {
      values.amount = 0;
      alert({
        message: 'A quantidade de testes gerados será definida automaticamente',
        type: 'info',
        position: 'bottom-center',
      })
    }
    setAutoAmount(!autoAmount);
  }

  const onRestoreJira = async () => {
    setLoading(true);
    await Request(REACT_APP_API_INTEGRATION_ALM_GEN || "").get(`/projects/${tenantId}/issues`, {
      params: {
        project: project,
        issue_type: issueType
      }
    }).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {}, [data, caseTest]);

  return (
    <Background>
      <Header title='Test CaseGen'/>
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Sistema / Aplicação *"
                variant="outlined"
                sx={{ m: 1 }}
                name="system"
                onChange={handleChange('system')}
                disabled={isSubmitting}
                value={values.system}
              />
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Épico"
                variant="outlined"
                sx={{ m: 1 }}
                name="epic"
                onChange={handleChange('epic')}
                disabled={isSubmitting}
                value={values.epic}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Funcionalidade *"
                variant="outlined"
                sx={{ m: 1 }}
                name="functionality"
                onChange={handleChange('functionality')}
                disabled={isSubmitting}
                value={values.functionality}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Regras de negócio"
                variant="outlined"
                sx={{ m: 1 }}
                name="rules"
                onChange={handleChange('rules')}
                disabled={isSubmitting}
                value={values.rules}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Estória de usuário"
                variant="outlined"
                sx={{ m: 1 }}
                name="userStory"
                onChange={handleChange('userStory')}
                disabled={isSubmitting}
                value={values.userStory}
                maxRows={3}
                minRows={3}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label={
                  values.userStory
                    ? 'Critérios de aceite *'
                    : 'Critérios de aceitação *'
                }
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                maxRows={3}
                minRows={3}
                name="acceptanceCriteria"
                onChange={handleChange('acceptanceCriteria')}
                disabled={isSubmitting}
                value={values.acceptanceCriteria}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Critérios de negação"
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                maxRows={3}
                minRows={3}
                name="denialCriteria"
                onChange={handleChange('denialCriteria')}
                disabled={isSubmitting}
                value={values.denialCriteria}
              />
            </WrapperFields>
            <>
              <ToogleContent>
                <Switch
                  checked={autoAmount}
                  onChange={() => defineAmount()}
                  disabled={isSubmitting}
                  sx={{
                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      color: '#183E62'
                    },
                    "&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track": {
                      backgroundColor: '#183E62'
                    },
                    "&.MuiSwitch-root .Mui-checked": {
                      color: '#FD784E'
                    },
                    "&.MuiSwitch-root .Mui-checked + .MuiSwitch-track": {
                      backgroundColor: '#FD784E'
                    }
                  }}
                  name="autoAmount"
                />
                <Title>Definir manualmente a quantidade de Casos de Teste</Title>
              </ToogleContent>
              <If condition={autoAmount}>
                <CustomTextField
                  style={{ width: '49%' }}
                  id="outlined-basic"
                  label="Quantidade de testes"
                  variant="outlined"
                  type="number"
                  sx={{ m: 1 }}
                  name="amount"
                  disabled={isSubmitting}
                  value={values.amount}
                  InputProps={{ inputProps: { min: 1, max: 50, step: 1 } }}
                  onChange={({ target }) => {
                    if (+target.value > 50) return;
                    setFieldValue('amount', target.value);
                  }}
                />
              </If>
            </>
          </AlignFields>

          <ButtonsContainer>
            <Tooltip title="Gerar Casos de Teste" placement="left">
              <ButtonBox>
                <If condition={!isSubmitting}>
                  <Play onPress={submitForm} color={colors.primary.main} />
                </If>
                <If condition={isSubmitting}>
                  <StyledReactLoading width={'5%'} type="bubbles" color={colors.primary.main} />
                </If>
              </ButtonBox>
            </Tooltip>
            <Tooltip title="Importar Jira" placement="left">
              <ButtonBox>
                <If condition={!loading}>
                  <Jira onPress={onRestoreJira} color={colors.secondary.main} />
                </If>
                <If condition={loading}>
                  <StyledReactLoading type="bubbles" color={colors.secondary.main} />
                </If>
              </ButtonBox>
            </Tooltip>
          </ButtonsContainer>
        </SectionFields>

        {!data.length ? (
          <TextEmpty>Nenhuma importação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <CollapseJira title="Jira: Histórias Importadas" type="Test" issue={data}/>
          </div>
        )}
        <SectionFields />

        {!caseTest.description.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <CollapseResponse
              item={caseTest}
              type="Caso de Teste"
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Tests;
