import React, { FC, useState, useEffect } from 'react';
import { Doc, Epic, History, Resource, Tests } from '~/assets/svg';
import Arrow from '~/assets/svg/Arrow';
import { useFormikContext, useStores } from '~/utils';
import { colors } from '~/theme';
import { Content, Description, TextContainer, Title } from './styles';

type Props = {
  data: any;
  onPress: () => void;
  disabled?: boolean;
};

const icons = {
  epic: Epic,
  resource: Resource,
  history: History,
  doc: Doc,
  tests: Tests,
};

const color = {
  epic: colors.primary.main,
  resource: colors.secondary.main,
  history: colors.tertiary.main,
  doc: colors.accent.main,
  tests: colors.secondary.dark,
};

const CardJira: FC<Props> = ({ onPress, data, disabled }) => {
  const { setFieldValue } = useFormikContext<StoryType.Forms>();
  const { jiraImport } = useStores();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (jiraImport.selectedCount === 0) {
      setIsSelected(false)
    }
  })

  const LimitedText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const handleClick = () => {
    if (jiraImport.editMode) {
      onPress();
    } else {
      setIsSelected((prevState) => !prevState);
      if (!isSelected) {
        setFieldValue('epic', data.fields.summary);
        setFieldValue('system', data.fields.summary);
        setFieldValue('description', data.fields.description);
        setFieldValue('functionality', data.fields.description);
        setFieldValue('acceptanceCriteria', data.fields.acceptance_criteria);
        setFieldValue('rules', data.fields.business_rule);
        jiraImport.addItemsImport(data);
      } else {
        setFieldValue('epic', "");
        setFieldValue('system', "");
        setFieldValue('description', "");
        setFieldValue('functionality', "");
        setFieldValue('acceptanceCriteria', "");
        setFieldValue('rules', "");
        jiraImport.removeItemsImport(data);
      }
      jiraImport.toggleSelectMode();
    }
  };

  return (
    <Content
      onClick={handleClick}
      style={{
        backgroundColor: disabled ? 'gray' : undefined,
        border: (isSelected && !jiraImport.editMode) ? `3.5px solid ${colors.primary.main}` : undefined,
      }}
    >
      <TextContainer>
        <Title>{data.key}</Title>
        <Description>{LimitedText(data.fields.summary, 70)}</Description>
        <Description>{LimitedText(data.fields.description, 70)}</Description>
      </TextContainer>
    </Content>
  );
};

export default CardJira;
