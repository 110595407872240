import React, { FC } from 'react';
import { Tooltip, Switch } from '@material-ui/core';
import { Play } from '~/assets/svg';
import { CollapseResponse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useFormikContext, useStores, alert, getTheme } from '~/utils';
import {
  AlignFields,
  Background,
  ButtonBox,
  ButtonsContainer,
  Container,
  SectionFields,
  StyledReactLoading,
  TextEmpty,
  WrapperFields,
  CustomTextField,
  Title,
  ToogleContent
} from './styles';

type Props = {};

const Epic: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<EpicType.Forms>();
    const { epic } = useStores()

    const [autoAmount, setAutoAmount] = React.useState(false);

    const defineAmount = () => {
      if (!autoAmount) {
        values.amount = 1;
        alert({
          message: 'A quantidade de epicos gerados será definida manualmente',
          type: 'info',
          position: 'bottom-center',
        })
      } else {
        values.amount = 0;
        alert({
          message: 'A quantidade de epicos gerados será definida automaticamente',
          type: 'info',
          position: 'bottom-center',
        })
      }
      setAutoAmount(!autoAmount);
    }

  return (
    <Background>
      <Header title='EpicGen'/>
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Sistema / Aplicação *"
                variant="outlined"
                sx={{ m: 1 }}
                name="application"
                disabled={isSubmitting}
                onChange={handleChange('application')}
                value={values.application}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Objetivo do Sistema / Aplicação *"
                variant="outlined"
                sx={{ m: 1 }}
                name="objective"
                disabled={isSubmitting}
                onChange={handleChange('objective')}
                value={values.objective}
              />
            </WrapperFields>
            <>
              <ToogleContent
                onClick={defineAmount}
              >
                <Switch
                  checked={autoAmount}
                  disabled={isSubmitting}
                  sx={{
                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      color: '#183E62'
                    },
                    "&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track": {
                      backgroundColor: '#183E62'
                    },
                    "&.MuiSwitch-root .Mui-checked": {
                      color: '#FD784E'
                    },
                    "&.MuiSwitch-root .Mui-checked + .MuiSwitch-track": {
                      backgroundColor: '#FD784E'
                    }
                  }}
                  name="autoAmount"
                />
                <Title>Definir manualmente a quantidade de epicos</Title>
              </ToogleContent>
              <If condition={autoAmount}>
                <CustomTextField
                  style={{ width: '49%' }}
                  id="outlined-basic"
                  label="Quantidade de epicos"
                  variant="outlined"
                  type="number"
                  sx={{ m: 1 }}
                  name="amount"
                  disabled={isSubmitting}
                  value={values.amount}
                  InputProps={{ inputProps: { min: 1, max: 50, step: 1 } }}
                  onChange={({ target }) => {
                    if (+target.value > 50) return;
                    setFieldValue('amount', target.value);
                  }}
                />
              </If>
            </>
          </AlignFields>
          <ButtonsContainer>
            <Tooltip title="Gerar Épico" placement="left">
              <ButtonBox>
                <If condition={!isSubmitting}>
                  <Play onPress={submitForm} color={colors.primary.main} />
                </If>
                <If condition={isSubmitting}>
                  <StyledReactLoading type="bubbles" color={colors.primary.main} />
                </If>
              </ButtonBox>
            </Tooltip>
          </ButtonsContainer>
          
        </SectionFields>
        {!epic.description.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <CollapseResponse
              type={'Épico'}
              item={epic}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Epic;
