import React, { FC, useState } from 'react';
import ReactLoading from 'react-loading';
import TextField from '@material-ui/core/TextField';
import { Play } from '~/assets/svg';
import { Collapse, Header, If } from '~/components';
import { colors } from '~/theme';
import { useFormikContext } from '~/utils';
import { mocks } from '~/utils/mock.utils';
import {
  AlignFields,
  Background,
  Container,
  SectionFields,
  WrapperFields,
} from './styles';

const Resource: FC = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm } =
    useFormikContext<ResourceType.Forms>();

  return (
    <Background>
      <Container>
        <Header title=''/>

        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Sistema / Aplicação"
                variant="outlined"
                sx={{ m: 1 }}
                name="system"
                disabled={isSubmitting}
                value="Crédito Pessoal"
                // onChange={handleChange('system')}
                // value={values.system}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Objetivo do Sistema / Aplicação"
                variant="outlined"
                sx={{ m: 1 }}
                name="objectiveSystem"
                disabled={isSubmitting}
                value="Cliente do Banco"
                // onChange={handleChange('objectiveSystem')}
                // value={values.objectiveSystem}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Épico"
                variant="outlined"
                sx={{ m: 1 }}
                name="epic"
                value="Decisão de Crédito"
                disabled={isSubmitting}
                // onChange={handleChange('epic')}
                // value={values.epic}
              />
            </WrapperFields>

            <WrapperFields>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Objetivo do Épico"
                variant="outlined"
                sx={{ m: 1 }}
                name="objectiveEpic"
                // onChange={handleChange('objectiveEpic')}
                disabled={isSubmitting}
                value="Sistema de aprovação/rejeição automatizado baseado em limiares de risco definidos"
                // value={values.objectiveEpic}
              />

              <TextField
                fullWidth
                id="outlined-basic"
                label="Funcionalidade"
                variant="outlined"
                sx={{ m: 1 }}
                name="resource"
                onChange={handleChange('resource')}
                disabled={isSubmitting}
                value="Assegurar a aprovação/rejeição de crédito com base em critérios de risco bem definidos"
                // value={values.resource}
              />
            </WrapperFields>
          </AlignFields>

          <If condition={!isSubmitting}>
            <Play onPress={submitForm} color={colors.secondary.main} />
          </If>

          <If condition={isSubmitting}>
            <ReactLoading type="bubbles" color={colors.secondary.main} />
          </If>
        </SectionFields>

        {mocks
          .resource({
            args: [
              {
                title: 'Sistema / Aplicação',
                description: 'Crédito Pessoal',
              },
              {
                title: 'Objetivo do Sistema / Aplicação',
                description: 'Cliente do Banco',
              },
              {
                title: 'Épico',
                description: 'DECISÃO DE CRÉDITO',
              },
              {
                title: 'Objetivo do Épico',
                description:
                  'Sistema de aprovação/rejeição automatizado baseado em limiares de risco definidos',
              },
              {
                title: 'Funcionalidade',
                description:
                  'Assegurar a aprovação/rejeição de crédito com base em critérios de risco bem definidos',
              },
            ],
          })
          .map(({ title, description, contents }) => (
            <Collapse
              content={[]}
              title={title}
              description={description}
              color={colors.secondary.main}
            />
          ))}
      </Container>
    </Background>
  );
};

export default Resource;
