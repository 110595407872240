import React, { FC } from "react";

type Props = {
  done?: boolean;
  onPress?: () => void;
  width?: number;
  style?: React.CSSProperties;
};

const Icon: FC<Props> = ({
  width = 45,
  style = { cursor: "pointer", alignSelf: "center", margin: 10, marginTop: 25 },
  done,
  onPress,
  ...rest
}) => (
  <svg
    {...rest}
    onClick={onPress}
    style={style}
    width={width}
    height="40"
    // viewBox="0 0 40 40"
    viewBox="0 0 448 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path 
      d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-242.7c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32L64 32zm0 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 224c-17.7 0-32-14.3-32-32l0-64zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
      fill="#FD784E"
    />
  </svg>
);

export default Icon;
