import { RouterStore } from 'mobx-react-router';
import CaseTestStore from './caseTest.store';
import EpicStore from './epic.store';
import UserStore from './user.store';
import StoryStore from './story.store';
import JiraImportStore from './jira.import.store';
import JiraExportStore from './jira.export.store';

class RootStore {
  user: UserStore;

  epic: EpicStore;

  caseTest: CaseTestStore;

  story: StoryStore;

  jiraImport: JiraImportStore;

  jiraExport: JiraExportStore;

  constructor() {
    this.user = new UserStore();
    this.caseTest = new CaseTestStore();
    this.epic = new EpicStore();
    this.story = new StoryStore();
    this.jiraExport = new JiraExportStore();
    this.jiraImport = new JiraImportStore();
  }
}

const store = new RootStore();

export { RouterStore, UserStore, CaseTestStore, EpicStore, StoryStore };

export default store;
