import React, { FC, useState, useEffect } from 'react';
import { Doc, Epic, History, Resource, Tests } from '~/assets/svg';
import Arrow from '~/assets/svg/Arrow';
import { colors } from '~/theme';
import { Content, Description, TextContainer, Title } from './styles';
import { If } from '~/components';
import { observer, useStores, alert } from '~/utils';

type Props = {
  _id: string;
  data: any;
  onPress: () => void;
  disabled?: boolean;
  type: string;
};

const icons = {
  epic: Epic,
  resource: Resource,
  history: History,
  doc: Doc,
  tests: Tests,
};

const color = {
  epic: colors.primary.main,
  resource: colors.secondary.main,
  history: colors.tertiary.main,
  doc: colors.accent.main,
  tests: colors.secondary.dark,
};

const CardResponse: FC<Props> = ({ onPress, _id, data, disabled, type }) => {
  const { jiraExport, epic, story, caseTest } = useStores();
  const [isSelected, setIsSelected] = useState(false);


  useEffect(() => {
    if (jiraExport.selectedCount === 0) {
      setIsSelected(false)
    }
  })

  const LimitedText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  const handleClick = () => {
    if (jiraExport.editMode) {
      onPress();
    } else {
      setIsSelected((prevState) => !prevState);
      if (!isSelected) {
        jiraExport.addItemsExport(data);
      } else {
        jiraExport.removeItemsExport(data);
      }
      jiraExport.toggleSelectMode();
    }
  };

  const setFeedback = async (id: Number, feedback: number) => {
    if(type === 'Épico') {
      const response = await epic.setEpicFeedback(_id, id, feedback);
    } else if(type === 'História') {
      const response = await story.setStoryFeedback(_id, id, feedback);
    } else if(type === 'Caso de Teste') {
      const response = await caseTest.setCaseTestFeedback(_id, id, feedback);
    }
    alert({
      message: `Feedback do ${type} armazenado com sucesso!`,
      type: 'success',
      position: 'bottom-center',
    });
  }

  return (
    <Content
      onClick={handleClick}
      style={{
        backgroundColor: disabled ? 'gray' : undefined,
        border: (isSelected && !jiraExport.editMode) ? `3.5px solid ${colors.primary.main}` : undefined,
      }}
    >
      <TextContainer>
        <Title>{data.summary}</Title>
        <If condition={type === 'Caso de Teste'}>
          <Description>{data.scenery}</Description>
        </If>
        <If condition={type != 'Caso de Teste'}>
          <Description>{LimitedText(data.description, 70)}</Description>
        </If>
      </TextContainer>
    </Content>
  );
};

export default observer(CardResponse);
