import React, { FC, useEffect, useState } from 'react';
import { Request } from '~/services';
import { Tooltip, Switch } from '@material-ui/core';
import { Jira, Play } from '~/assets/svg';
import { CollapseResponse, Header, If, CollapseJira } from '~/components';
import { colors } from '~/theme';
import { alert, useFormikContext, useStores, REACT_APP_API_INTEGRATION_ALM_GEN } from '~/utils';
import {
  AlignFields,
  Background,
  ButtonBox,
  ButtonsContainer,
  Container,
  SectionFields,
  WrapperFields,
  StyledReactLoading,
  TextEmpty,
  CustomTextField,
  CustomFormControl,
  CustomInputLabel,
  CustomSelect,
  CustomMenuItem,
  Title,
  ToogleContent
} from './styles';

type Props = {};

const Story: FC = (): JSX.Element => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<StoryType.Forms>();
  const { story } = useStores();
  const [ tenantId, setTenantID ]  = useState("f24c99fdc5ee49368bcc783259d21eb6");
  const [ project, setProject ]  = useState("IJ");
  const [ issueType, setIssueType ]  = useState("Epic");

  const [autoAmount, setAutoAmount] = React.useState(false);

  const defineAmount = () => {
    if (!autoAmount) {
      values.amount = 1;
      alert({
        message: 'A quantidade de Histórias gerados será definida manualmente',
        type: 'info',
        position: 'bottom-center',
      })
    } else {
      values.amount = 0;
      alert({
        message: 'A quantidade de Histórias gerados será definida automaticamente',
        type: 'info',
        position: 'bottom-center',
      })
    }
    setAutoAmount(!autoAmount);
  }

  const onRestoreJira = async () => {
    setLoading(true);
    await Request(REACT_APP_API_INTEGRATION_ALM_GEN || "").get(`/projects/${tenantId}/issues`, {
      params: {
        project: project,
        issue_type: issueType
      }
    }).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {}, [data, story]);

  return (
    <Background>
      <Header title="StoryGen" />
      <Container>
        <SectionFields>
          <AlignFields>
            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Épico *"
                variant="outlined"
                sx={{ m: 1 }}
                name="epic"
                onChange={handleChange('epic')}
                disabled={isSubmitting}
                value={values.epic}
              />

              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Descrição *"
                variant="outlined"
                sx={{ m: 1 }}
                name="description"
                disabled={isSubmitting}
                onChange={handleChange('description')}
                value={values.description}
              />
            </WrapperFields>

            <WrapperFields>
              <CustomTextField
                fullWidth
                id="outlined-basic"
                label="Regras de Negócio"
                variant="outlined"
                sx={{ m: 1 }}
                multiline
                minRows={5}
                name="business_rule"
                onChange={handleChange('business_rule')}
                disabled={isSubmitting}
                value={values.business_rule}
              />

              <CustomFormControl
                fullWidth
                variant="outlined"
                sx={{ m: 1 }}
                disabled={isSubmitting}
              >
                <CustomInputLabel id="demo-simple-select-label">
                  Severidade
                </CustomInputLabel>
                <CustomSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="severity"
                  onChange={handleChange}
                  value={values.severity}
                >
                  <CustomMenuItem value={'baixa'}>Baixa</CustomMenuItem>
                  <CustomMenuItem value={'media'}>Média</CustomMenuItem>
                  <CustomMenuItem value={'alta'}>Alta</CustomMenuItem>
                  <CustomMenuItem value={'critica'}>Crítica</CustomMenuItem>
                </CustomSelect>
              </CustomFormControl>
            </WrapperFields>
            <>
              <ToogleContent>
                <Switch
                  checked={autoAmount}
                  onChange={() => defineAmount()}
                  disabled={isSubmitting}
                  sx={{
                    "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      color: '#183E62'
                    },
                    "&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track": {
                      backgroundColor: '#183E62'
                    },
                    "&.MuiSwitch-root .Mui-checked": {
                      color: '#FD784E'
                    },
                    "&.MuiSwitch-root .Mui-checked + .MuiSwitch-track": {
                      backgroundColor: '#FD784E'
                    }
                  }}
                  name="autoAmount"
                />
                <Title>Definir manualmente a quantidade de Histórias</Title>
              </ToogleContent>
              <If condition={autoAmount}>
                <CustomTextField
                  style={{ width: '49%' }}
                  id="outlined-basic"
                  label="Quantidade de Histórias"
                  variant="outlined"
                  type="number"
                  sx={{ m: 1 }}
                  name="amount"
                  disabled={isSubmitting}
                  value={values.amount}
                  InputProps={{ inputProps: { min: 1, max: 50, step: 1 } }}
                  onChange={({ target }) => {
                    if (+target.value > 50) return;
                    setFieldValue('amount', target.value);
                  }}
                />
              </If>
            </>
          </AlignFields>

          <ButtonsContainer>
            <Tooltip title="Gerar História" placement="left">
              <ButtonBox>
                  <If condition={!isSubmitting}>
                    <Play onPress={submitForm} color={colors.primary.main} />
                  </If>
                  <If condition={isSubmitting}>
                    <StyledReactLoading
                      type="bubbles"
                      color={colors.primary.main}
                    />
                  </If>
              </ButtonBox>
            </Tooltip>
            <Tooltip title="Importar Jira" placement="left">
              <ButtonBox>
                <If condition={!loading}>
                  <Jira onPress={onRestoreJira} color={colors.secondary.main} />
                </If>
                <If condition={loading}>
                  <StyledReactLoading type="bubbles" color={colors.secondary.main} />
                </If>
              </ButtonBox>
            </Tooltip>
          </ButtonsContainer>
        </SectionFields>
        {!data.length ? (
          <TextEmpty>Nenhuma importação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <CollapseJira title="Jira: Épicos Importados" type="Story" issue={data}/>
          </div>
        )}
        <SectionFields />

        {!story.description.length ? (
          <TextEmpty>Nenhuma informação até o momento.</TextEmpty>
        ) : (
          <div>
            {/* Renderize aqui o conteúdo desejado quando response estiver disponível */}
            <CollapseResponse
              type={'História'}
              item={story}
            />
          </div>
        )}
      </Container>
    </Background>
  );
};

export default Story;
