import { sum } from 'lodash';
import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Dict } from 'styled-components/dist/types';
import { Story } from '~/scenes';
import { GeneratorAPI } from '~/services/api';

export default class JiraExportStore {
  @observable
  items_export: JiraType.ExportItensForms[] = [];

  @observable
  selectMode: boolean = false;

  @observable
  editMode: boolean = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'JiraStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  get selectedCount() {
    return this.items_export.length;
  }

  @action
  toggleSelectMode() {
    this.selectMode = true;
    if (this.items_export.length === 0) {
      this.selectMode = false;
    }
  }

  @action
  toggleEditMode(selected: boolean) {
    this.editMode = selected;
    this.items_export = [];
  }

  @action
  addItemsExport = (data: any) => {
    this.items_export.push(data);
  }

  @action
  removeItemsExport = (data: any) => {
    this.items_export = this.items_export.filter(item => item !== data);
  }

  @action
  exportEpicToJira = async (tenent_id: string, project: string, issueType: string, epic_key?: string): Promise<boolean> => {
    let model: any[] = [];
    this.items_export.forEach(item => {
      const dict: Dict = {
        project: project,
        summary: item.summary,
        description: item.description,
        issuetype: {
          hierarchyLevel: 1,
          name: issueType,
        },
      };
      model.push(dict);
    });
    const rsp = await GeneratorAPI.exportItemsToJira({
      tenent_id,
      issues: model,
    });
    return true;
  };

  @action
  exportStoryToJira = async (tenent_id: string, project: string, issueType: string, epic_key?: string): Promise<boolean> => {
    let model: any[] = [];
    console.log(this.items_export);
    this.items_export.forEach(item => {
      const dict: Dict = {
        project: project,
        summary: item.summary,
        description: `${item.description}\n\nAcceptance Criteria: \n${item?.acceptance_criteria}\n\nBusiness Rule: \n${item?.business_rule}\n\nStory Points: ${item?.story_points}\n\nSeverity: ${item?.severity}`,
        issuetype: {
          hierarchyLevel: 0,
          name: issueType,
        },
      };
      model.push(dict);
    });
    const rsp = await GeneratorAPI.exportItemsToJira({
      tenent_id,
      issues: model,
    });
    return true;
  };
  
  @action
  exportCaseTestToJira = async (tenent_id: string, project: string, issueType: string, epic_key?: string): Promise<boolean> => {
    let model: any[] = [];
    this.items_export.forEach(item => {
      const dict: Dict = {
        project: project,
        summary: item.summary,
        description: item.description,
        issuetype: {
          hierarchyLevel: 1,
          name: issueType,
        },
      };
      model.push(dict);
    });
    const rsp = await GeneratorAPI.exportItemsToJira({
      tenent_id,
      issues: model,
    });
    return true;
  };


}
