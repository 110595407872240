import '@mdxeditor/editor/style.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import { Collapse } from 'react-collapse';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import remarkBreaks from "remark-breaks";
import { Download } from '~/assets/svg';
import Arrow from '~/assets/svg/Arrow';
import { clearMarkdown, useStores } from '~/utils';
import {
  Content,
  ContentCollapse,
  Description,
  DownloadOptionsSection,
  DownloadText,
  SectionButtons,
  SectionHeader,
  SectionText,
  Title,
} from './styles';

type ContentType = {
  title?: string;
  Value?: string | number;
  description?: string;
  items?: {
    title: string;
    items: string[];
  }[];
};

type Props = {
  title?: string;
  description?: string;
  content?: ContentType[];
  color?: string;
};

const CollapseModule: FC<Props> = ({
  title,
  description,
  content = [],
  color = '',
}) => {
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [opened, setOpened] = useState(false);
  const [markdown, setMarkdown] = useState('');
  const { caseTest } = useStores();
  const ref = useRef<any>();
  const refEditor = useRef<any>();

  const onDownloadCSV = () => {
    const header = "title,subTitle,value";
    const data = content?.map(({ items }) => {
      const itemsData = items?.map(({ title, items }) => {
        const [subTitle, value] = title.split(':');
        return `${title},${subTitle},${value}`;
      }).join(';');
      return `${itemsData}`;
    });

    const csv = [header, ...data].join('\n');

    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8',
    });

    FileSaver.saveAs(blob, `${title}.csv`);
  }

  const onDownloadTXT = () => {
    const blob = new Blob([clearMarkdown(contentToMarkdown())], {
      type: 'text/plain;charset=utf-8',
    });

    FileSaver.saveAs(blob, `${title}.txt`);
  };

  const onDownloadWORD = () => {
    const blob = new Blob([clearMarkdown(refEditor.current?.getMarkdown())], {
      type: 'application/msword',
    });

    FileSaver.saveAs(blob, `${title}.doc`);
  };

  const contentToMarkdown = () => {
    let data = '';

    content?.forEach(({ title = '', Value = '', description = '', items = [] }) => {
      data = `${data}


### ${title.replaceAll('_', ' ')}&nbsp; \n
${Value || description}

${items?.map(
  ({ title, items }) => `
  &nbsp; \n

#### ${title}&nbsp; \n
${items.map((txt) => `· ${txt}`).join('&nbsp; \n')} `,
)}&nbsp; \n
`;
    });

    return data;
  };

  return (
    <Content>
      <SectionHeader>
        <SectionText onClick={() => setOpened(!opened)}>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </SectionText>

        <SectionButtons>
          <ReactToPrint content={() => ref.current} documentTitle={title}>
            <DownloadOptionsSection visible={downloadVisible}>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <DownloadText onClick={handlePrint}>PDF</DownloadText>
                )}
              </PrintContextConsumer>
              <DownloadText onClick={onDownloadCSV}>CSV</DownloadText>
              <DownloadText onClick={onDownloadTXT}>TXT</DownloadText>
              <DownloadText onClick={onDownloadWORD}>WORD</DownloadText>
            </DownloadOptionsSection>
            <Download
              onPress={() => setDownloadVisible(!downloadVisible)}
              color={'#fd784e'}
            />
          </ReactToPrint>

          <Arrow
            opened={!opened}
            onPress={() => setOpened(!opened)}
            color={'#fff'}
          />
        </SectionButtons>
      </SectionHeader>

      <Collapse isOpened={opened}>
        <ContentCollapse ref={ref}>
          <Markdown remarkPlugins={[remarkGfm, remarkBreaks]}>{contentToMarkdown()}</Markdown>
        </ContentCollapse>
      </Collapse>
    </Content>
  );
};
export default CollapseModule;
