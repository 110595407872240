import React, { FC, useState } from "react";
import { Add, Close } from "~/assets/svg";
import {
  Content,
  CustomTextField,
  Header,
  Section,
  SectionFields,
  Title,
} from "./styles";
import If from "../If";
import { useStores, alert } from '~/utils';

type Props = {
  _id: string;
  issue: any;
  type: string;
  opened: boolean;
  setOpened: (value: boolean) => void;
};

const ModalEditing: FC<Props> = ({issue, _id, type, opened, setOpened}) => {
  const [summary, setSummary] = useState(issue.summary);
  const [description, setDescription] = useState(issue.description);
  const [scenery, setScenery] = useState(issue.scenery || undefined);
  const [acceptance_criteria, setAcceptanceCriteria] = useState(issue.acceptance_criteria || undefined);
  const { epic, story, caseTest } = useStores()
  const onCloseModal = () => {
    setOpened(false);
  }

  const onSaveChanges = () => {
    issue.summary = summary;
    issue.description = description;
    if(scenery) issue.scenery = scenery;
    if(acceptance_criteria) issue.acceptance_criteria = acceptance_criteria;
    saveItems();
    setOpened(false);
  }

  const saveItems = async () => {
    if(type === 'Épico'){
      const response = await epic.updateEpic(_id, issue);
    } else if(type === 'História'){
      const response = await story.updateStory(_id, issue);
    } else if(type === 'Caso de Teste'){
      const response = await caseTest.updateCaseTest(_id, issue);
    }
    alert({
      message: `${type} atualizado com sucesso!`,
      type: 'success',
      position: 'bottom-center',
    });
  };
  
  return (
    <If condition={opened}>
      <Content>
        <Section>
          <Header>
            <Title>
              Edição de {type}
            </Title>
            <Close onPress={() => onCloseModal()} color={"#FFF"} />
          </Header>

          <SectionFields>
            <CustomTextField
              label="Summary"
              name="summary"
              value={summary}
              onChange={(event) => setSummary(event.target.value)}
              variant="outlined"
              sx={{ m:1 }}
              maxRows={5}
              minRows={1}
            />

            <If condition={issue.scenery}>
              <CustomTextField
                label="Scenery"
                value={scenery}
                onChange={(event) => setScenery(event.target.value)}
                name="scenery"
                variant="outlined"
                sx={{ m:1 }}
                multiline
                maxRows={20}
                minRows={3}
              />
            </If>

            <CustomTextField
              label="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              name="description"
              variant="outlined"
              sx={{ m:1 }}
              multiline
              maxRows={20}
              minRows={3}
            />

            <If condition={issue.acceptance_criteria}>
              <CustomTextField
                label="Acceptance Criteria"
                value={acceptance_criteria}
                onChange={(event) => setAcceptanceCriteria(event.target.value)}
                name="acceptance_criteria"
                variant="outlined"
                sx={{ m:1 }}
                multiline
                maxRows={20}
                minRows={3}
              />
            </If>

            <Add onPress={() => onSaveChanges()} />
          </SectionFields>
        </Section>
      </Content>
    </If>
  );
};

export default ModalEditing;
