type Basic = {
  title?: string;
  description?: string;
  args?: {
    title: string;
    description?: string;
    items?: string[];
  }[];
};

// ##############################
// #### EPIC
// ##############################

const contentsEpic1 = [
  {
    title: 'Objetivos',
    items: [
      'Obter todas as informações necessárias do cliente de forma eficiente e segura.',
      'Garantir a facilidade e acessibilidade do processo de fornecimento de informações pelo cliente',
    ],
  },
  {
    title: 'Funcionalidades',
    items: [
      'Formulários digitais para inserção de dados pessoais, financeiros e profissionais pelo cliente.',
      'Upload seguro de documentos necessários, como comprovantes de renda e identidade.',
      'Verificação de integridade e completude das informações fornecidas. - Sistema de autenticação e segurança para proteger as informações do cliente.',
    ],
  },
];

const contentsEpic2 = [
  {
    title: 'Principias Objetivos',
    items: [
      'Avaliar de forma precisa o risco associado ao crédito solicitado.',
      'Utilizar dados históricos e padrões para melhorar a precisão da avaliação de risco.',
    ],
  },
  {
    title: 'Funcionalidades',
    items: [
      'Algoritmos de pontuação de crédito baseados em critérios como histórico de crédito, renda e emprego.',
      'Integração com bases de dados externas para verificação de crédito e histórico financeiro.',
      'Modelos preditivos para avaliar a probabilidade de inadimplência.',
      'Registro e monitoramento de todas as avaliações de risco realizadas.',
    ],
  },
];

const contentsEpic3 = [
  {
    title: 'Principias Objetivos',
    items: [
      'Tomar decisões informadas sobre a aprovação de crédito com base na análise de risco.',
      'Comunicar de forma clara e transparente a decisão ao cliente.',
    ],
  },
  {
    title: 'Funcionalidades',
    items: [
      'Sistema de aprovação/rejeição automatizado baseado em limiares de risco definidos.',
      'Geração de relatórios detalhados sobre a análise realizada e os motivos da decisão.',
      'Notificações e comunicações automatizadas para informar os clientes sobre o resultado da solicitação.',
      'Recurso para revisão manual em casos de decisão limítrofe ou apelação do cliente',
    ],
  },
];

// ##############################
// #### RESOURCE
// ##############################

export const mocks = {
  epic: ({ args = [] }: Basic) => [
    {
      title: 'Coleta de Informações do Cliente',
      description:
        'Este épico foca na primeira etapa do processo de análise de crédito, que é a coleta de informações essenciais do cliente.',
      contents: [...args, ...contentsEpic1],
    },
    {
      title: 'Avaliação de Risco de Crédito',
      description:
        'Este épico aborda a análise detalhada das informações do cliente para avaliar o risco de crédito.',
      contents: [...args, ...contentsEpic2],
    },
    {
      title: 'Decisão de Crédito',
      description:
        'Este épico envolve a tomada de decisão baseada na análise de risco, determinando se o crédito será aprovado ou não.',
      contents: [...args, ...contentsEpic3],
    },
  ],
  resource: ({ args = [] }: Basic) => [
    {
      title:
        'Sistema de aprovação/rejeição automatizado baseado em limiares de risco definidos',
      description:
        'Este sistema automatizado é projetado para avaliar as solicitações de crédito com base em critérios de risco bem definidos. Utilizando algoritmos avançados, o sistema analisa os dados do cliente, como histórico de crédito, renda, despesas, entre outros, para calcular uma pontuação de risco. Se essa pontuação estiver dentro de limiares pré- estabelecidos, a solicitação de crédito é aprovada; caso contrário, é rejeitada. O objetivo é otimizar o processo de decisão de crédito, garantindo rapidez, eficiência e consistência nas avaliações de risco.',
      contents: [
        ...args,
        {
          title: 'Objetivo da funcionalidade',
          items: [
            'Limiares de Risco: Os limiares de risco devem ser definidos com base em análises históricas e ajustados conforme a necessidade para refletir a política de crédito atual do banco e o ambiente econômico.',
            'Atualização de Critérios: Os critérios de risco devem ser periodicamente revisados e atualizados para garantir que permaneçam relevantes e eficazes na avaliação do risco de crédito.',
            'Proteção de Dados: O sistema deve cumprir rigorosamente as leis de proteção de dados, garantindo a segurança e a privacidade das informações do cliente.',
            'Transparência: Os clientes devem ser informados sobre os critérios utilizados na avaliação de suas solicitações de crédito, mantendo a transparência do processo.',
            'Revisão Manual: Deve existir a possibilidade de revisão manual das decisões tomadas pelo sistema automatizado, especialmente em casos de contestação por parte do cliente.',
          ],
        },
        {
          title: 'Regras de negócio da funcionalidade',
          description:
            'Sistema de aprovação/rejeição automatizado baseado em limiares de risco definido',
        },
        {
          title: 'Estórias de usuário',
          items: [
            'Estória de Usuário 1: Como cliente, quero que minha solicitação de crédito seja avaliada rapidamente pelo sistema automatizado para saber se fui aprovado ou rejeitado sem demora.',
            'Estória de Usuário 2: Como analista de crédito, preciso acessar facilmente as decisões do sistema automatizado para revisar casos específicos e garantir a precisão das avaliações.',
            'Estória de Usuário 3: Como gerente de risco, quero ajustar os limiares de risco no sistema automatizado para refletir as mudanças na política de crédito do banco ou no ambiente econômico.',
            'Estória de Usuário 4: Como cliente que teve a solicitação de crédito rejeitada pelo sistema automatizado, desejo solicitar uma revisão manual da decisão para garantir que todos os meus dados foram considerados corretamente.',
            'Estória de Usuário 5: Como profissional de TI responsável pelo sistema, preciso garantir que o sistema de aprovação/rejeição automatizado esteja sempre atualizado e funcionando corretamente, para manter a eficiência e a precisão do processo de decisão de crédito.',
          ],
        },
      ],
    },
  ],
  history: ({ args = [] }: Basic) => [
    {
      title: 'Critérios de Aceitação',
      description: '',
      contents: [
        ...args,
        {
          title: 'Acesso às Decisões',
          description:
            'O analista de crédito deve ter acesso imediato às decisões de aprovação/rejeição geradas pelo sistema automatizado através de uma interface de usuário intuitiva.',
        },
        {
          title: 'Detalhamento das Decisões',
          description:
            'Cada decisão de crédito acessada deve incluir detalhes sobre a pontuação de risco do cliente, os critérios avaliados e a justificativa para a aprovação ou rejeição.',
        },
        {
          title: 'Filtragem e Busca',
          description:
            'A interface deve permitir que o analista filtre e busque decisões específicas usando vários critérios, como data, status da decisão, pontuação de risco, entre outros.',
        },
        {
          title: 'Solicitação de Revisão',
          description:
            'Deve haver uma funcionalidade que permita ao analista de crédito iniciar um processo de revisão manual diretamente da interface de visualização da decisão.',
        },
        {
          title: 'Segurança e Conformidade',
          description:
            'O acesso às decisões deve estar em conformidade com as políticas de segurança de dados do banco, garantindo que apenas pessoal autorizado possa visualizar ou alterar as informações de crédito.',
        },
      ],
    },
    {
      title: 'Critérios de Negação',
      description: '',
      contents: [
        ...args,
        {
          title: 'Acesso Indevido',
          description:
            'O sistema não permitirá que usuários sem as devidas permissões acessem decisões de crédito ou informações detalhadas dos clientes.',
        },
        {
          title: 'Informações Incompletas',
          description:
            'Decisões de crédito que não apresentem todas as informações necessárias para uma revisão completa (como critérios avaliados e justificativas) serão marcadas como incompletas e sinalizadas para revisão adicional.',
        },
        {
          title: 'Interface Complexa',
          description:
            'Interfaces de usuário que exijam treinamento extensivo ou sejam difíceis de navegar não serão aceitas, para garantir a eficiência no processo de revisão de crédito.',
        },
        {
          title: 'Falha de Conformidade',
          description:
            'Qualquer funcionalidade que não esteja em plena conformidade com as leis de proteção de dados aplicáveis será negada para evitar violações de privacidade e segurança de dados.',
        },
        {
          title: 'Demora na Solicitação de Revisão',
          description:
            'Processos ou interfaces que resultem em atrasos significativos na solicitação de revisões manuais de decisões de crédito serão considerados inadequados.',
        },
      ],
    },
  ],
  doc: ({ args = [] }: Basic) => [
    {
      title: 'Documentação do Código',
      description: '',
      contents: [
        ...args,
        {
          title: 'Condicional de Validação',
          items: [
            'O código verifica o valor de uma variável chamada $validacao.',
            'Baseado no valor dessa variável, diferentes ações são tomadas.',
          ],
        },
        {
          title: 'Ações Condicionalmente Executadas:',
          items: [
            'invalido: Se $validacao é igual a "invalido", atualiza um valor no objeto JSON $body usando uma função ou método Update Value To Json. O método Inv é chamado com o argumento $$[linha.body) após a atualização, mas a sintaxe exata e o propósito não estão claros devido ao corte do texto.',
            'com tag: Se $validacao é igual a "com tag", um objeto é deletado do JSON $body utilizando Delete Object From Json com o argumento $$[linha.body).',
            'vazio: Se $validacao é igual a "vazio", o valor é atualizado no objeto JSON $body semelhante ao caso "invalido", mas parece que uma função ou método $f( é chamado, cujo propósito é desconhecido devido à truncagem do texto.',
            'modificar: Se $validacao é igual a "modificar", novamente atualiza-se um valor no objeto JSON $body, possivelmente com a mesma função ou método indefinido $f(.',
          ],
        },
        {
          title: 'Estórias de usuário',
          items: [
            'Estória de Usuário 1: Como cliente, quero que minha solicitação de crédito seja avaliada rapidamente pelo sistema automatizado para saber se fui aprovado ou rejeitado sem demora.',
            'Estória de Usuário 2: Como analista de crédito, preciso acessar facilmente as decisões do sistema automatizado para revisar casos específicos e garantir a precisão das avaliações.',
            'Estória de Usuário 3: Como gerente de risco, quero ajustar os limiares de risco no sistema automatizado para refletir as mudanças na política de crédito do banco ou no ambiente econômico.',
            'Estória de Usuário 4: Como cliente que teve a solicitação de crédito rejeitada pelo sistema automatizado, desejo solicitar uma revisão manual da decisão para garantir que todos os meus dados foram considerados corretamente.',
            'Estória de Usuário 5: Como profissional de TI responsável pelo sistema, preciso garantir que o sistema de aprovação/rejeição automatizado esteja sempre atualizado e funcionando corretamente, para manter a eficiência e a precisão do processo de decisão de crédito.',
          ],
        },
      ],
    },
  ],
};
